import { StoreTransferDirectRepository } from '@/models/repository/storetransferdirectrepository';
import StoreTransferProvider from './storetransferprovider';

export default class StoreTransferDirectProvider extends StoreTransferProvider {
    
    public async getStoreTransferRepository(storeTransferHeaderUid: string): Promise<StoreTransferDirectRepository> {
        const data = await this.getStoreTransferJSON(storeTransferHeaderUid);

        const repository = new StoreTransferDirectRepository();
        repository.fromJson(data);

        return repository;
    }

    public async save(repository: StoreTransferDirectRepository): Promise<any> {
        const json = repository.toJson();

        const response = await (await this.getApiV2()).post(`/storetransfers`, json);
        
        return response.data;
    }
}