import { ConsumptionType } from '@/models/enums/consumptiontype.enum';

export default class SlotTemplate {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public consumption_type: ConsumptionType = ConsumptionType.EATIN;
    public name?: string = "";
    public start_time?: Date;
    public end_time?: Date;
    public slot_duration?: Date;
    public on_site_maximum_orders?: number;
    public takeaway_maximum_orders?: number;
    public delivery_maximum_orders?: number;
    public active?: boolean = true;
    public monday?: boolean = true;
    public tuesday?: boolean = true;
    public wednesday?: boolean = true;
    public thursday?: boolean = true;
    public friday?: boolean = true;
    public saturday?: boolean = true;
    public sunday?: boolean = true;
    public slot_prebook_timeout_minute?: number = 15;

    get maximumOrders(): number | undefined {
        switch (this.consumption_type) {
            case ConsumptionType.EATIN: return this.on_site_maximum_orders;
            case ConsumptionType.TAKEAWAY: return this.takeaway_maximum_orders;
            case ConsumptionType.DELIVERY: return this.delivery_maximum_orders;
        }
    }

    set maximumOrders(value: number | undefined) {
        switch (this.consumption_type) {
            case ConsumptionType.EATIN: this.on_site_maximum_orders = value; break;
            case ConsumptionType.TAKEAWAY: this.takeaway_maximum_orders = value; break;
            case ConsumptionType.DELIVERY: this.delivery_maximum_orders = value; break;
        }
    }

    constructor(init?: Partial<SlotTemplate>) {
        Object.assign(this, init);
    }

    fromJson(init: any): SlotTemplate {
        for (const key in init) {
            try {
                switch (key) {
                    case 'start_time':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    case 'end_time':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    case 'slot_duration':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`SlotTemplate fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }
}