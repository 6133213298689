import moment from "moment";

export abstract class DocumentLine {
    uid?: string;
    id?: number;
    order_number?: number;

    constructor(init?: Partial<DocumentLine>) {
        Object.assign(this, init);
    }

    fromJson(init: any): DocumentLine {
        console.log("DocumentLine.fromJson");

        this.uid = init["uid"] ?? undefined;
        this.id = init["id"] ?? undefined;
        this.order_number = init["order_number"] ?? undefined;

        return this;
    }

    toJson(): any {
        return {
            ...this,
        }
    }
}

export abstract class DocumentHeader {
    uid?: string;
    id?: number;
    journal_uid?: string;
    date?: Date;
    creation_date?: Date;
    modification_date?: Date;
    number?: number;
    easystore_origin_id: number = 4;
    global_user_uid?: string;

    constructor(init?: Partial<DocumentHeader>) {
        Object.assign(this, init);
    }

    fromJson(init: any): DocumentHeader {
        console.log("Document.fromJson");

        this.uid = init["uid"] ?? undefined;
        this.id = init["id"] ?? undefined;
        this.journal_uid = init["journal_uid"] ?? undefined;
        this.date = init["date"] ? new Date(init["date"]) : undefined;
        this.creation_date = init["creation_date"] ? new Date(init["creation_date"]) : undefined;
        this.modification_date = init["modification_date"] ? new Date(init["modification_date"]) : undefined;
        this.number = init["number"] ?? undefined;
        this.easystore_origin_id = Number(this.easystore_origin_id);
        this.global_user_uid = init["global_user_uid"] ?? undefined;

        return this;
    }

    toJson(): any {
        return {
            ...this,
            date: this.date ?
                moment(this.date)
                    .set('year', this.date.getFullYear())
                    .set('month', this.date.getMonth())
                    .set('date', this.date.getDate())
                    .toISOString(true) :
                undefined,
        }
    }
}
