export default class Tag {
    [key: string]: any;

    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public active?: boolean = true;

    public background_color?: number;
    public text_color?: number;
    public position?: number;

    constructor(init?: Partial<Tag>) {
        Object.assign(this, init);
    }
}