import { CommercialHeader, CommercialLine, CommercialPayment } from "@/models/document/commercialdocument";
import { DocumentLine } from "./document";

export class SaleLine extends CommercialLine {
    parent_sale_line_uid?: string;
    discount_reason_uid?: string;

    constructor(init?: Partial<SaleLine>) {
        super(init);
    }

    fromJson(init: any): DocumentLine {
        super.fromJson(init);

        this.parent_sale_line_uid = init["parent_sale_line_uid"] ?? undefined;
        this.discount_reason_uid = init["discount_reason_uid"] ?? undefined;

        return this;
    }
}

export class SalePayment extends CommercialPayment {
    constructor(init?: Partial<SalePayment>) {
        super(init);
    }
}

export class SaleHeader extends CommercialHeader {
    customer_uid?: string;
    customer_address_line?: string;
    customer_address_line2?: string;
    customer_zip_code?: string;
    customer_city?: string;
    customer_country_id?: number;
    customer_phone_number?: string;
    invoice_address_line?: string;
    invoice_address_line2?: string;
    invoice_zip_code?: string;
    invoice_city?: string;
    invoice_country_id?: number;
    invoice_phone_number?: string;
    
    constructor(init?: Partial<SaleHeader>) {
        super(init);
    }

    fromJson(init: any): CommercialHeader {
        super.fromJson(init);

        this.customer_uid = init["customer_uid"] ?? undefined;
        this.customer_address_line = init["customer_address_line"] ?? undefined;
        this.customer_address_line2 = init["customer_address_line2"] ?? undefined;
        this.customer_zip_code = init["customer_zip_code"] ?? undefined;
        this.customer_city = init["customer_city"] ?? undefined;
        this.customer_country_id = init["customer_country_id"] ?? undefined;
        this.customer_phone_number = init["customer_phone_number"] ?? undefined;
        this.invoice_address_line = init["invoice_address_line"] ?? undefined;
        this.invoice_address_line2 = init["invoice_address_line2"] ?? undefined;
        this.invoice_zip_code = init["invoice_zip_code"] ?? undefined;
        this.invoice_city = init["invoice_city"] ?? undefined;
        this.invoice_country_id = init["invoice_country_id"] ?? undefined;
        this.invoice_phone_number = init["invoice_phone_number"] ?? undefined;

        return this;
    }
}