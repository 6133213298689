import BaseProvider from './baseprovider'
import { PagedParams } from '@/models/paged/pagedparams'
import MailTemplate from '@/models/mailtemplate'
import PagedMailTemplates from '@/models/paged/pagedmailtemplates'

export default class MailTemplateProvider extends BaseProvider {
  /**
   * Fetch a resultset of mail templates linked to specific entities
   * @param entityIds The entities which the mail templates must be linked to
   * @returns A resultset of mail templates
   */
  public async fetchMailTemplates(): Promise<MailTemplate[]> {
    const response = await (await this.getApiV2()).get(`/MailTemplates`)

    const mailTemplates = response.data.map(
      (mailTemplate: any) => new MailTemplate(mailTemplate)
    )

    return mailTemplates
  }

  /**
   * Fetch a paged resultset of mail templates linked to specific entities
   * @param entityIds The entities which the mail templates must be linked to
   * @param pagedParams The pagination parameters
   * @returns A paged resultset of mail templates
   */
  public async fetchPagedMailTemplates(pagedParams?: PagedParams): Promise<PagedMailTemplates> {
    let params: String = ''
    
    params = params.addPagedParams(pagedParams)
    params = params !== '' ? `?${params}` : ''

    const response = await (await this.getApiV2()).get(`MailTemplates${params}`)

    return new PagedMailTemplates(response.data)
  }

  /**
   * Fetch the Mail Template through API based on its uid
   * @param mailTemplateUid The Mail Template uid
   * @returns The Mail Template corresponding the uid
   */
  public async fetchMailTemplateFromUid(mailTemplateUid: string): Promise<MailTemplate> {
    const response = await (await this.getApiV2()).get(`/MailTemplates/${mailTemplateUid}`)

    return new MailTemplate(response.data)
  }

  /**
   * Fetch the default Mail Template of the current user through API based on its uid
   * @returns The default Mail Template of the current user
   */
  public async fetchUserTemplate(): Promise<MailTemplate> {
    const response = await (await this.getApiV2()).get(`/MailTemplates/DefaultUserTemplate`)

    return new MailTemplate(response.data)
  }

  /**
   * Save the Mail Template using a POST request through the API
   * @param mailTemplate The Mail Template to save
   * @returns The saved Mail Template
   */
  public async saveMailTemplate(mailTemplate: MailTemplate): Promise<MailTemplate> {
    const response = await (await this.getApiV2()).post(`/MailTemplates/`, mailTemplate)

    return new MailTemplate(response.data)
  }
}
