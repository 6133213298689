import BaseProvider from '@/providers/baseprovider'
import { SettingModel } from '@/states/setting'

export default class SettingProvider extends BaseProvider {
  public async fetchSettings(): Promise<SettingModel[] | null> {
    const response = await (await this.getApiV2()).get('/Settings')

    return response?.data
      ? response.data.map((d: any) => d as SettingModel)
      : null
  }
}
