<template>
  <Accordion :multiple="true">
    <template
        v-for="(group, groupName) in groupBy(items?.filter((i) => i.group && i.group && !i?.items?.length), (subItem) => subItem.group)"
        :key="group">
      <AccordionTab :header="groupName">
        <li :key="i" v-for="(item, i) in group" class="flex column align-items-start">
          <router-link class="bo-list-item" :to="getLink(item.link)" v-show="item.visible">
            <i v-if="item.icon" :class="'fas fa-' + item.icon + ' color-' + item.color"/>
            <img v-else-if="item.iconPath" width="28" class="image" :src="item.iconPath"/>
            <div v-else-if="item.iconHtml" :class="'color-' + item.color" v-html="item.iconHtml"/>
            <span class="bo-list-item-content">{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </AccordionTab>
    </template>
  </Accordion>
</template>

<script lang="ts">
import RouteHelper from '@/helpers/routehelper'
import { SubMenuItem } from '@/menu/menu'
import { groupBy } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SidebarAccordionItem',
  props: {
    items: Array<SubMenuItem>
  },
  setup() {
    const getLink = (path: string) => RouteHelper.getLink(path)

    return {
      groupBy,
      getLink
    }
  }
})
</script>
