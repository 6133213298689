export default class CurrentModel {
    [key: string]: any;
    total_vat_included!: number;
    total_vat_excluded!: number;
    count_orders?: number;
    count_receipts?: number;

    constructor(init: Partial<CurrentModel> & Required<{total_vat_included: number, total_vat_excluded: number}>) {
        Object.assign(this, init);
    }
}
