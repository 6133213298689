import { CommercialHeader, CommercialLine, CommercialPayment } from "@/models/document/commercialdocument";
import { DocumentLine } from "./document";

export class PurchaseLine extends CommercialLine {
    parent_purchase_line_uid?: string;

    constructor(init?: Partial<PurchaseLine>) {
        super(init);
    }

    fromJson(init: any): DocumentLine {
        super.fromJson(init);

        this.parent_purchase_line_uid = init["parent_purchase_line_uid"] ?? undefined;

        return this;
    }
}

export class PurchasePayment extends CommercialPayment {
    constructor(init?: Partial<PurchasePayment>) {
        super(init);
    }
}

export class PurchaseHeader extends CommercialHeader {
    supplier_uid?: string;

    constructor(init?: Partial<PurchaseHeader>) {
        super(init);
    }
 
    fromJson(init: any): CommercialHeader {
        super.fromJson(init);

        this.supplier_uid = init["supplier_uid"] ?? undefined;

        return this;
    }
}