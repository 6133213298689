interface String {
    addParams(key: string,
              values?: (string | number | Date | boolean | null | undefined)[],
              array?: boolean): string;
    addPagedParams(pagedParams: any, defaults?: any): string;
}

if(!String.prototype.addParams) String.prototype.addParams =
    function (this: string,
              key: string,
              values?: (string | number | Date | boolean | undefined)[],
              array: boolean = false) {
        values = values?.filter(value => value !== undefined);
        if ((values?.length ?? 0) <= 0) return this;
        if (array) return this + `${this.length > 0 ? '&' : ''}${key}=[${values?.map(v => v === null ? 'null': v).join(',')}]`
        else return this + `${this.length > 0 ? '&' : ''}${values!.map(value => `${key}=${value}`).join(`&`)}`
    };

if(!String.prototype.addPagedParams) String.prototype.addPagedParams =
    function (this: string,
              pagedParams: any, defaults?: any) {
        let p = this;
        p = p.addParams("pageNumber", [pagedParams?.pageNumber ?? defaults?.pagedNumber ?? 1 ]);
        p = p.addParams("pageSize", [pagedParams?.pageSize ?? defaults?.pagedSize ?? 25 ]);
        p = p.addParams("sortField", [pagedParams?.sortField ?? defaults?.sortField ?? 'name' ]);
        p = p.addParams("sortOrder", [pagedParams?.sortOrder ?? defaults?.sortOrder ?? 'asc' ]);

        if (pagedParams?.filters)
            Object.keys(pagedParams.filters)
                .forEach(key => {
                    switch (key) {
                        case 'consumption_type':
                            // Convert id array to comma-separated string because filters must be unique string
                            if (pagedParams.filters[key].length > 0)
                                p = p.addParams(`filters[${key}]`, [pagedParams.filters[key].join(',')]);
                            break;
                        default:
                            p = p.addParams(`filters[${key}]`, [pagedParams.filters[key]]);
                    }
                });
        return p;
    };