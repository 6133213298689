import { DocumentHeader, DocumentLine } from "./document";
import { ItemDocumentHeader, ItemDocumentLine } from "./itemdocument";

export abstract class StoreTransferLine extends ItemDocumentLine {

    parent_store_transfer_line_uid?: string;

    fromJson(init: any): DocumentLine {
        super.fromJson(init);

        this.parent_store_transfer_line_uid = init["parent_store_transfer_line_uid"] ?? undefined;

        return this;
    }
}

export abstract class StoreTransferHeader extends ItemDocumentHeader {
    reference?: string;
    from_store_id?: number;
    to_store_id?: number;
    total: number = 0;

    fromJson(init: any): DocumentHeader {
        super.fromJson(init);

        this.reference = init["reference"] ?? undefined;
        this.from_store_id = init["from_store_id"] ?? undefined;
        this.to_store_id = init["to_store_id"] ?? undefined;
        this.total = Number(init["total"]);

        return this;
    }
}