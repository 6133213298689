import BaseProvider from '@/providers/baseprovider';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedAccountingGetSales from '@/models/paged/pagedaccountinggetsales';
import PagedAccountingGetPurchases from '@/models/paged/pagedaccountinggetpurchases';
import moment from 'moment/moment';

/*export interface AccountingTransferPayload {
    entity_id: number;
    invoice_ids: number[];
}
/*export interface GetAccountingPayload {
    nameOnly?: boolean,
    pagedParams?: PagedParams,
    entityId: number,
    storeIds?: number[]
}*/

export default class AccountingProvider extends BaseProvider {
    public async transferSales(entityId: number,
        invoiceIds: number[]): Promise<void> {
        await (await this.getApiV2()).post(`/accounting/transfersalestoaccounting/`, {
            entity_id: entityId,
            invoice_ids: invoiceIds
        });

        return;
    }
    public async getSales(entityId: number,
        pagedParams?: PagedParams,
        params?: {
            storeIds?: number[];
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedAccountingGetSales> {
        let p: String = '';
        p = p.addParams("entityId", [entityId], false);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("nameOnly", [false], false);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'id',
            sortOrder: 'desc',
        });
        p = p !== '' ? `?${p}` : '';

        if (params?.fromDate && params?.toDate)
            p = `/${moment(params.fromDate).format('yyyy-MM-DD HH:mm:ss')}/${moment(params.toDate).format('yyyy-MM-DD HH:mm:ss')}${p}`;

        const response = await (await this.getApiV2()).get(`/accounting/getsalestosendtoaccounting${p}`);

        const pagedAccountingGetSales = new PagedAccountingGetSales(response.data);

        return pagedAccountingGetSales;
    }

    public async transferPurchases(entityId: number,
        invoiceIds: number[]): Promise<void> {
        await (await this.getApiV2()).post(`/accounting/transferpurchasestoaccounting/`, {
            entity_id: entityId,
            invoice_ids: invoiceIds
        });

        return;
    }

    public async getPurchases(entityId: number,
        pagedParams?: PagedParams,
        params?: {
            storeIds?: number[];
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedAccountingGetPurchases> {
        let p: String = '';
        p = p.addParams("entityId", [entityId], false);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("nameOnly", [false], false);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'id',
            sortOrder: 'desc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/accounting/getpurchasestosendtoaccounting${p}`);

        const pagedAccountingGetPurchases = new PagedAccountingGetPurchases(response.data);

        return pagedAccountingGetPurchases;
    }
}