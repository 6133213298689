import BaseProvider from './baseprovider'
import PaymentType from '@/models/paymenttype'

export default class PaymentTypeProvider extends BaseProvider {
  /**
   * Fetch all payment types
   */
  public async fetchPaymentTypes(): Promise<PaymentType[]> {
    const response = await (await this.getApiV2()).get('/PaymentTypes')
    return response.data.map((d: any) => {
      const r = new PaymentType()
      return r.fromJson(d)
    })
  }

  /**
   * Fetch all payment types that can be used in backoffice
   */
  public async fetchBackofficePaymentTypes(): Promise<PaymentType[]> {
    const response = await (await this.getApiV2()).get('/PaymentTypes/BackOffice')
    return response.data.map((d: any) => {
      const r = new PaymentType()
      return r.fromJson(d)
    })
  }

  public async fetchFreePaymentTypes(): Promise<PaymentType[]> {
    const response = await (await this.getApiV2()).get('/PaymentTypes/FreePayments')
    return response.data.map((d: any) => {
      const r = new PaymentType()
      return r.fromJson(d)
    })
  }
}
