import {defineStore} from 'pinia';
import {BONotificationParams, BONotificationValue} from '@/components/Dialog/BONotification.vue';

type AppNotification = {
    show: Boolean,
    msg: Error | BONotificationParams | string,
    custom: BONotificationParams | null,
    results: BONotificationValue | null
}

export interface AppState {
    notification: AppNotification | null
}

export const initialState = (): AppState => ({
    notification: null
});

const useAppState = defineStore('app', {
    state: initialState,
    getters: {
        getNotification: (state): AppNotification | null  => state.notification,
    },
    actions: {
        showNotification(msg: Error | BONotificationParams | string, custom: BONotificationParams | null = null) {
            this.notification = { show: true, msg, custom, results: null }
        },
        setNotificationValue(results: BONotificationValue | null): void {
            if (this.notification) {
                this.notification.results = results;
                this.notification.show = false;
            } else throw new Error ("No app notification was displayed. No value should be set. ")
        }
    }
});

export default useAppState;