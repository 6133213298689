import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import axios from 'axios';

export const SUPPORT_LOCALES = ['fr', 'en', 'nl']

export function setupI18n(options = {
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {},
  silentTranslationWarn: true,
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false,
}) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

const i18n = setupI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: { },
  silentTranslationWarn: true,
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false,
})
export { i18n };

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  axios.defaults.headers.common['Accept-Language'] = locale
  //document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n: any, locale: string) {
  // load locale messages with dynami import

  const [oldFile, newFile] = await Promise.all([
    await import(`./locales/${locale}.json`),
    await import(`./translate/intl_${locale}.json`)
  ]);

  const messages = { ...oldFile.default, ...newFile.default };

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages)

  return nextTick()
}