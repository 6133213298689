export default class StoreFreePaymentType {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public payment_type_id?: number;
    public store_id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public order_number?: number;
    public active?: boolean;
    public deferred_payment?: number;

    constructor(init?: Partial<StoreFreePaymentType>) {
        Object.assign(this, init);
    }

    fromJson(init: any): StoreFreePaymentType {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`StoreFreePaymentType fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }
}