import {createStore, useStore as useVuexStore, Store as VuexStore, ActionContext} from 'vuex'
import SettingProvider from '@/providers/settingprovider';
import {InjectionKey, watch} from 'vue';
import {authState} from '@/states/auth';

export interface SettingModel {
    id: number;
    reference: string;
    value: string;
}

export interface SettingState {
    provider: SettingProvider;
    loading: boolean;
    error: Error | null;
    data: SettingModel[] | null;
}

const initialState: SettingState = {
    provider: new SettingProvider(),
    loading: false,
    error: null,
    data: null
}

export const settingState: VuexStore<SettingState> = createStore<SettingState>({
    state: initialState,
    getters: {
        provider: (state: SettingState) => state.provider,
        reference: (state: SettingState) => (reference: string) => (state.data?.find(d => d.reference === reference) ?? null)
    },
    actions: {
        load: async (store: ActionContext<SettingState, any>, uid: string): Promise<void> => {
            store.commit('setLoading', true);
            store.commit('setData', null);
            store.commit('setError', null);

            try {
                store.commit('setData', await store.getters.provider.fetchSettings());
            } catch (e) {
                store.commit('setError', e);
            }

            store.commit('setLoading', false);
        }
    },
    mutations: {
        setLoading(state: SettingState, payload: boolean) { state.loading = payload; },
        setError(state: SettingState, payload: Error) { state.error = payload; },
        setData(state: SettingState, payload: SettingModel[] | null) { state.data = payload; }
    },
    plugins: [
        (store) => watch(() => authState.state.globalUser, () => { if (!store.state.data) store.dispatch('load'); })
    ]
})

export const settingStateKey: InjectionKey<VuexStore<SettingState>> = Symbol();
export const useSettingState = (): VuexStore<SettingState> => useVuexStore(settingStateKey);
export default useSettingState;