export default class SaleLine {
    [key: string]: any;
    uid?: string;
    id?: number;
    item_id?: number;
    item_uid?: string;
    sale_header_id?: number;
    canceled_sale_line_id?: number;
    quantity?: number;
    transferred_quantity?: number;
    description?: string;
    unit_price?: number;
    discount_rate?: number;
    total?: number;
    total_net_vat_included?: number;
    total_net_vat_excluded?: number;
    vat_rate?: number;
    vat_code?: string;
    vat_country_code?: string;
    parent_sale_line_id?: number;
    measure_unit_id?: number;

    constructor(init?: Partial<SaleLine>) {
        Object.assign(this, init);
    }
    fromJson(init: any): SaleLine {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`SaleLine fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
