import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "bo-list-item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createBlock(_component_Accordion, { multiple: true }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupBy(_ctx.items?.filter((i) => i.group && i.group && !i?.items?.length), (subItem) => subItem.group), (group, groupName) => {
        return (_openBlock(), _createBlock(_component_AccordionTab, {
          key: group,
          header: groupName
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (item, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: "flex column align-items-start"
              }, [
                _withDirectives(_createVNode(_component_router_link, {
                  class: "bo-list-item",
                  to: _ctx.getLink(item.link)
                }, {
                  default: _withCtx(() => [
                    (item.icon)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass('fas fa-' + item.icon + ' color-' + item.color)
                        }, null, 2))
                      : (item.iconPath)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            width: "28",
                            class: "image",
                            src: item.iconPath
                          }, null, 8, _hoisted_1))
                        : (item.iconHtml)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 2,
                              class: _normalizeClass('color-' + item.color),
                              innerHTML: item.iconHtml
                            }, null, 10, _hoisted_2))
                          : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(item.name)), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]), [
                  [_vShow, item.visible]
                ])
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["header"]))
      }), 128))
    ]),
    _: 1
  }))
}