export default class StorePos {
  [key: string]: any;
  public id?: number;
  public uid?: string;
  public name?: string;
  public joyn_pos_unique_id?: string;

  constructor(init?: Partial<StorePos>) {
    Object.assign(this, init);
  }

  fromJson(init: any): StorePos {
    for (const key in init) {
      try {
        switch (key) {
          default:
            this[key] = init[key];
        }
      } catch (err) { console.warn(`StorePos fromJson conversion warning: ${key} is missing`) }
    }
    return this;
  }
}