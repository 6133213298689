import {InjectionKey} from 'vue';
import {createStore, useStore as useVuexStore, Store as VuexStore} from 'vuex'

import {
    executeRestAction,
    failedRestAction,
    initialRestAction,
    RestAction,
    successRestAction
} from '@/states/restAction';
import VAT from "@/models/vat";
import {deepCopy} from '@/helpers/deepCopy';
import VATProvider from "@/providers/vatprovider";

//const restomax = [56, 528, 250, 442, 724];

export interface VatState {
    provider: VATProvider,
    ready: boolean,
    values: RestAction<VAT[]>
}

const initialState: VatState = {
    provider: new VATProvider(),
    ready: false,
    values: initialRestAction()
}

export const vatState: VuexStore<VatState> = createStore<VatState>({
    state: initialState,
    mutations: {
        ready (state, payload: boolean) { state.ready = payload },
        init (state) { state = initialState },
        load (state, payload: Partial<VatState>) {
            state.ready = true;
            if (payload.values) state.values = deepCopy(payload.values);
        },
    },
    getters: {
        all: (state) => state.values.data ?? [],
        countryId: (state) => (value: number) => state.values.data?.find(e => e.country_id === value) ?? null,
        code: (state) => (value: string) => state.values.data?.find(e => e.code === value) ?? null,
    },
    actions: {
        async load(store) {
            store.commit('ready', false);
            store.state.values = executeRestAction();
            try {
                store.commit('load', {
                    values: successRestAction(await store.state.provider.fetchVAT())
                });
            } catch (e: any) {
                store.state.values = failedRestAction(e.message);
            }
        },
    },
    plugins: [
        (store) => store.dispatch('load')
    ]
})
export const vatStateKey: InjectionKey<VuexStore<VatState>> = Symbol()
export const useVatState = (): VuexStore<VatState> => useVuexStore(vatStateKey);
export default useVatState;