import { StoreTransferHeader, StoreTransferLine } from "@/models/document/storetransferdocument";
import { FlowStatus } from "../enums/flowstatus.enum";
import { FlowStatusValidation } from "../enums/flowstatusvalidation.enum";
import { DocumentHeader } from "./document";

export class StoreTransferFlowLine extends StoreTransferLine {

    constructor(init?: Partial<StoreTransferFlowLine>) {
        super(init);
    }
}

export class StoreTransferFlowHeader extends StoreTransferHeader {
    flow_status: FlowStatus = FlowStatus.PREPARATION;
    flow_status_validation?: FlowStatusValidation;

    constructor(init?: Partial<StoreTransferFlowHeader>) {
        super(init);
    }

    fromJson(init: any): DocumentHeader {
        super.fromJson(init);

        this.flow_status = Number(init["flow_status"]);
        this.flow_status_validation = init["flow_status_validation"] ? Number(init["flow_status_validation"]) : undefined;

        return this;
    }
}