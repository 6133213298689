import BaseProvider from '@/providers/baseprovider';
import Sale from '@/models/sale';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedSales from '@/models/paged/pagedsales';
import { SaleRepository } from '@/models/repository/salerepository';
import ItemProvider from './itemprovider';
import ItemStore from '@/models/itemstore';
import moment from 'moment/moment';
import {ICommercialLineTransfer, ICommercialPaymentTransfer} from '@/stores/commercial';

export default class SaleProvider extends BaseProvider {
    public async fetchPagedSales(entityIds: number[], pagedParams?: PagedParams,
        params?: {
            storeIds?: number[];
            posIds?: number[];
            journalIds?: number[],
            journalTypeIds?: number[],
            flowTypeIds?: number[],
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedSales> {

        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'date',
            sortOrder: 'desc'
        });
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("posIds", params?.posIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("journalTypeIds", params?.journalTypeIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p !== '' ? `?${p}` : '';

        if (params?.fromDate && params?.toDate)
            p = `/${moment(params.fromDate).format('yyyy-MM-DD HH:mm:ss')}/${moment(params.toDate).format('yyyy-MM-DD HH:mm:ss')}${p}`;

        const response = await (await this.getApiV2()).get(`sales${p}`);

        // Revert comma-separated string to id array
        if (response.data?.filters['consumption_type'])
            response.data.filters['consumption_type'] =
                response.data.filters['consumption_type'].split(',').map((s: string) => +s);

        return new PagedSales(response.data);
    }

    public async getSaleFromUid(saleUid: string): Promise<Sale> {
        const response = await (await this.getApiV2()).get(`/sales/${saleUid}`);
        const sale = this.getSale(response.data);
        return sale;
    }

    private getSale(data: any): Sale {
        const r = new Sale();
        r.fromJson(data);
        return r;
    }

    public async getSaleJSON(saleHeaderUid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/sales/${saleHeaderUid}`);

        return response.data;
    }

    public async getSaleRepository(saleHeaderUid: string): Promise<SaleRepository> {
        const data = await this.getSaleJSON(saleHeaderUid);

        const repository = new SaleRepository();
        repository.fromJson(data);

        return repository;
    }

    public async save(repository: SaleRepository): Promise<any> {
        const json = repository.toJson();

        const response = await (await this.getApiV2()).post(`/sales`, json);

        return response.data;
    }

    public async saveDraftTransfer(repository: SaleRepository, journalUid: string, lines: ICommercialLineTransfer[]): Promise<any> {
        const response = await (await this.getApiV2()).post(`/sales/${repository.header.model.uid}/transfer/${journalUid}/draft`, {
            lines: lines.map((line) => ({
                uid: line.lineUid,
                quantity: line.quantity,
            }))
        });

        return response.data;
    }

    public async transfer(repository: SaleRepository, journalUid: string, lines: ICommercialLineTransfer[], payment: ICommercialPaymentTransfer): Promise<any> {
        const response = await (await this.getApiV2()).post(`/sales/${repository.header.model.uid}/transfer/${journalUid}`, {
            lines: lines.map((line) => ({
                uid: line.lineUid,
                quantity: line.quantity,
            })),
            payment: {
                amount: payment.amount,
                date: payment.date ?? new Date()
            }
        });

        return response.data;
    }

    public async send(repository: SaleRepository,
        to: string[],
        subject: string,
        body: string,): Promise<any> {
        const response = await (await this.getApiV2()).post(`/sales/${repository.header.model.uid}/send`, {
            to,
            subject,
            body,
        });

        return response.data;
    }

    public async preview(uid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/sales/${uid}/preview`);

        return response.data;
    }

    public async deposit(uid: string, amount: number,): Promise<any> {
        const json = {
            amount,
        };

        const response = await (await this.getApiV2()).post(`/sales/${uid}/deposit`, json);

        return response.data;
    }

    public async searchItems(entityIds: number[], search: string, storeId: number, priceListUid?: string | undefined): Promise<Array<any>> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("search", [search]);
        p = p.addParams("storeId", [storeId]);

        if (priceListUid)
            p = p.addParams("priceListUid", [priceListUid]);


        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/sales/searchitems${p}`);

        for (let idx = 0; idx < response.data?.length ?? 0; idx++) {
            if ("stores" in response.data[idx]) {
                response.data[idx].stores.map((d: any) => new ItemStore(d));
            } else {
                response.data[idx].stores = await (new ItemProvider().fetchItemStores(response.data[idx].item_id)) ?? [];
            }
        }

        return response.data;
    }
}
