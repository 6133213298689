import PropositionTo from "./propositionto";

export default class Proposition {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public active?: boolean = true;
    public description_fr?: string;
    public description_en?: string;
    public description_nl?: string;
    public quantity_type: number = 1;
    public retrieve_price: boolean = false;
    public upselling?: boolean = false;
    public upselling_method?: number;
    public minimum_selected_item?: number | null;
    public maximum_selected_item?: number | null;

    public entities?: any[] = [];
    public propositions_to?: PropositionTo[] = [];

    constructor(init?: Partial<Proposition>) {
        Object.assign(this, init);
    }
}