import { StoreWebhookEventType } from '@/models/enums/storewebhookeventtype.enum';

export default class StoreWebhookEvent {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public event_type?: StoreWebhookEventType = StoreWebhookEventType.ORDER_CREATED;
    public url?: string = "";

    constructor(init?: Partial<StoreWebhookEvent>) {
        Object.assign(this, init);
    }

    fromJson(init: any): StoreWebhookEvent {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`StoreWebhookEvent fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }
}