import { StoreTransferFlowHeader, StoreTransferFlowLine } from "@/models/document/storetransferflowdocument";
import { StoreTransferRepository, StoreTransferLineRepository, StoreTransferHeaderRepository, IStoreTransferRepositoryParameters } from "@/models/repository/storetransferrepository";
import { FlowStatus } from "../enums/flowstatus.enum";
import { FlowStatusValidation } from "../enums/flowstatusvalidation.enum";

export class StoreTransferFlowHeaderRepository extends StoreTransferHeaderRepository<StoreTransferFlowHeader> {

    //#region flowStatus

    get flowStatus(): FlowStatus {
        return this.model.flow_status;
    }

    set flowStatus(value: FlowStatus) {
        this.setFlowStatus(value);
    }

    setFlowStatus(value: FlowStatus) {
        this.model.flow_status = value;

        this.edited = true;
    }

    //#endregion

    //#region flowStatusValidation

    get flowStatusValidation(): FlowStatusValidation | undefined {
        return this.model.flow_status_validation;
    }

    set flowStatusValidation(value: FlowStatusValidation | undefined) {
        this.setFlowStatusValidation(value);
    }

    setFlowStatusValidation(value: FlowStatusValidation | undefined) {
        this.model.flow_status_validation = value;

        this.edited = true;
    }

    //#endregion

    clear() {
        super.clear();
    }

    constructor(init?: Partial<StoreTransferFlowHeader>) {
        super(StoreTransferFlowHeader, init);
    }
}

export class StoreTransferFlowLineRepository extends StoreTransferLineRepository<StoreTransferFlowLine> {

    constructor(init?: Partial<StoreTransferFlowLine>) {
        super(StoreTransferFlowLine, init);
    }
}

interface IStoreTransferFlowRepositoryParameters extends IStoreTransferRepositoryParameters<StoreTransferFlowHeaderRepository, StoreTransferFlowLineRepository> {

}

export class StoreTransferFlowRepository extends StoreTransferRepository<StoreTransferFlowHeaderRepository, StoreTransferFlowLineRepository> {
    constructor(params?: IStoreTransferFlowRepositoryParameters) {
        super(StoreTransferFlowHeaderRepository, StoreTransferFlowLineRepository, params?.header, params?.lines);
    }
}