import Listing from "@/models/listing";
import ListingViewModel from "@/models/view/listingviewmodel";
import BaseProvider from "./baseprovider"
import GlobalCategory from "@/models/globalcategory";
import Reporting from "@/models/reporting";
import Cached from '@/plugins/cached';
import moment from 'moment';

export default class ListingProvider extends BaseProvider {
    private static longTTL = 1000 * 60 * 30;

    public async getDashboardListingViewModels(): Promise<ListingViewModel[]> {
        const response = await Cached.request(
            'GET',
            `listings/dashboard`,
            undefined, undefined,
            2,
            false,
            { ttl: ListingProvider.longTTL });

        const r = this.received(response, d => this.getListingViewModel(d));

        return r;
    }

    public async getDashboardListing(listingId: number): Promise<ListingViewModel> {
        const response = await Cached.request(
            'GET',
            `listings/dashboard/${listingId}`,
            undefined, undefined,
            2,
            false,
            { ttl: ListingProvider.longTTL });

        const r = this.received(response, d => this.getListingViewModel(d), false);

        return r;
    }

    public async getDashboardListingResults(listingId: number, storeIds: number[], fromDate: Date, toDate: Date): Promise<ListingViewModel> {
        const currentDate: Date = moment().startOf('day').toDate();
        const fromString: string = moment(fromDate).format("yyyy-MM-DD");
        const toString: string = moment(toDate).format("yyyy-MM-DD");

        const response = await Promise.all([
            Cached.request(
                'GET',
                `listings/dashboard/results/${listingId}/${fromString}/${toString}`,
                { storeIds }, undefined,
                2,
                false,
                toDate < currentDate ? { ttl: ListingProvider.longTTL } : true),
            new Promise((resolve) => setTimeout(resolve, 2000))
        ]);

        const r = this.received(response[0]);

        return r;
    }

    private getListingViewModel(data: any): ListingViewModel {
        return new ListingViewModel({
            listing: new Listing({
                id: data.id,
                name_fr: data.name_fr,
                name_en: data.name_en,
                name_nl: data.name_nl,
                icon_azure_path: data.icon_azure_path,
            }),
            globalCategory: new GlobalCategory({
                id: data.global_category_id,
                name_fr: data.global_category_name_fr,
                name_en: data.global_category_name_en,
                name_nl: data.global_category_name_nl,
            }),
            reportings: data.reportings?.map((r: any) => new Reporting(r)),
        });
    }
}