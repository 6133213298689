export default class DocumentStatus {
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public type: number = 1;
    public flow_type?: number;
    public active: boolean = true;

    constructor(init?: Partial<DocumentStatus>) {
        Object.assign(this, init);
    }
}