export default class CASummarySalesModel {
    [key: string]: any;
    group_date?: Date;
    store_id!: number;
    turnover_vat_included?: number;
    turnover_vat_included_eatin?: number;
    turnover_vat_included_take_away?: number;
    turnover_vat_included_retail?: number;
    turnover_vat_included_delivery?: number;
    turnover_vat_excluded?: number;
    turnover_vat_excluded_eatin?: number;
    turnover_vat_excluded_take_away?: number;
    turnover_vat_excluded_retail?: number;
    turnover_vat_excluded_delivery?: number;
    total_vat_included_by_vat_rate_percent_21?: number;
    total_vat_included_by_vat_rate_percent_12?: number;
    total_vat_included_by_vat_rate_percent_6?: number;
    total_vat_included_by_vat_rate_percent_0?: number;
    total_vat_excluded_by_vat_rate_percent_21?: number;
    total_vat_excluded_by_vat_rate_percent_12?: number;
    total_vat_excluded_by_vat_rate_percent_6?: number;
    vat_rate_percent_21?: number;
    vat_rate_percent_12?: number;
    vat_rate_percent_6?: number;
    tickets?: number;
    quantity?: number;
    guest_count?: number;

    constructor(init: Partial<CASummarySalesModel> & Required<{store_id: number}>) {
        Object.assign(this, init);
        if (init.group_date) this.group_date = new Date(init.group_date);
    }
}
