<template>
  <Dialog
    class="loginas-edition-dialog"
    :header="$filters.capitalizeFirstLetter($t('login_as'))"
    :visible="displayDialog" @update:visible="closeDialog"
    :maximizable="true"
    :modal="true"
    :style="{ width: '70vw' }"
    @hide="closeDialog"
  >
    <div class="loginas-dialog" v-show="displayDialog">
      <div class="mt-3">
        <div class="flex">
          <InputText
              autocomplete="off" spellcheck="false"
              v-model="searchText"
              @keydown.enter="search"
              type="search"
              autofocus
          />
          <Button
            class="ml-2 background-gradient-sunrise"
            @click="search"
            :label="$t('search')"
            icon="fas fa-search"
          />
        </div>
        <div style="height: 10px" />
        <DataTable
          :value="users"
          :loading="loading"
          dataKey="global_user_uid"
          responsiveLayout="scroll"
        >
          <template #empty>
            <ElementsEmptyIndicator
              :indicator="'no_result'"
              :description="$t('no_records_found')"
            />
          </template>
          <template #loading>
            <div
              class="
                flex-grow-1 flex flex-column
                align-items-center
                justify-content-center
              "
            >
              <ProgressSpinner></ProgressSpinner>
              <div class="mt-4">
                {{ $filters.capitalizeFirstLetter($t("loading_in_progress")) }}
              </div>
            </div>
          </template>
          <Column field="organization_id" header="Organization ID"></Column>
          <Column field="organization_name" header="Organization Name"></Column>
          <Column field="global_user_name" header="Name"></Column>
          <Column field="global_user_email" header="Email"></Column>
          <Column field="store_names" header="Stores" style="max-width: 8rem">
            <!-- <template #body="slotProps">
              <span v-tooltip="':)'">{{ slotProps.data.store_names }}</span>
            </template> -->
          </Column>
          <Column :exportable="false" style="min-width: 8rem">
            <template #body="slotProps">
              <Button
                icon="pi pi-check"
                class="p-button p-button-rounded mr-2 background-gradient-gold"
                :label="$filters.capitalizeFirstLetter($t('login'))"
                @click="loginAs(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import ElementsEmptyIndicator from "@/components/EmptyIndicator.vue";

import useProvider from "@/hooks/provider";

export default defineComponent({
  components: {
    ElementsEmptyIndicator,
  },
  emits: ["closed", "loginAsClicked"],
  setup(props, { emit }) {
    const displayDialog = ref(false);
    const searchText = ref<string>("");
    const provider = useProvider();
    const users = ref<any[]>([]);
    const loading = ref<boolean>(false);

    const closeDialog = () => {
      if (displayDialog.value) {
        displayDialog.value = false;
        emit("closed");
      }
    };

    const openDialog = () => {
      displayDialog.value = true;
    };

    const search = async () => {
      if (!searchText.value) return;

      loading.value = true;

      users.value =
        await provider.globalUser.fetchGlobalUsersFromOrganizationSystem(searchText.value);

      loading.value = false;
    };

    const loginAs = (user: any) => {
      console.log("loginAs", user);

      emit("loginAsClicked", user);

      closeDialog();
    };

    return {
      displayDialog,
      closeDialog,
      openDialog,
      search,
      searchText,
      loading,
      loginAs,
      users,
    };
  },
});
</script>
