import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "src"]
const _hoisted_2 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "empty-indicator" }, _ctx.$attrs), [
    _createElementVNode("img", {
      width: _ctx.width,
      src: require('@/assets/img_' + _ctx.indicator + '.svg')
    }, null, 8, _hoisted_1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.description), 1)
  ], 16))
}