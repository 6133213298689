<template>
  <div id="nav-level1">
    <ul>
      <li class="toggle-button-item">
        <div class="toggle-button" href="#" v-on:click.prevent="toggleMenu">
          <div class="bar"></div>
        </div>
      </li>
      <li>
        <router-link
            v-if="!(authState.state.organization?.is_system ?? false)" class="nav-hint-parent"
            :to="RouteHelper.getLink('dashboard/ca')">
          <i class="fas fa-tachometer-alt" />
          <span class="nav-hint">Dashboard</span>
        </router-link>
      </li>
      <li v-for="(item, idx) in mainMenu" :key="idx">
        <router-link class="nav-hint-parent" :to="RouteHelper.getLink(item.link)">
          <i :class="'fas fa-' + item.icon" />
          <span class="nav-hint">{{ $t(item.name) }}</span>
        </router-link>
      </li>
      <li>
        <router-link
            class="nav-hint-parent"
            :to="RouteHelper.getLink(`account/${(authState.state.organization?.is_system ?? false) ? 'activelicenses' : 'credentials'}`)">
          <i
              v-if="!authState.state.globalUser?.pictureContent" class="fa fa-user color-white"
              style="font-size: 18px" />
          <div class="nav-hint user-info flex flex-column" style="width: auto">
                    <span style="white-space: nowrap">
                      {{ $t('welcome-globaluser', { name: authState.state.globalUser?.name }) }}
                    </span>
            <div class="language-options">
              <Button label="FR" @click="changeLanguage('fr')" class="p-button-link" />
              <Button label="EN" @click="changeLanguage('en')" class="p-button-link" />
              <Button label="NL" @click="changeLanguage('nl')" class="p-button-link" />
            </div>
            <Button class="p-button-outlined" :label="$t('logout')" @click="logout()" />
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import useBreakpoints from '@/compositions/useBreakpoints'
import RouteHelper from '@/helpers/routehelper'
import { getMainMenu } from '@/menu/menu'
import useAuthState from '@/states/auth'
import { globalState } from '@/states/global'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    show: Boolean
  },
  computed: {
    RouteHelper() {
      return RouteHelper
    }
  },
  emits: [ 'language:updated', 'show:updated' ],
  setup(props, { emit }) {
    const authState = useAuthState()
    const { type } = useBreakpoints()
    const i18n = useI18n()
    const router = useRouter()
    const menuShown = computed({
      get: () => props.show,
      set: (value: boolean) => emit('show:updated', value)
    })

    const mainMenu = computed(() => getMainMenu(authState.state.organization?.is_system ?? false))

    const changeLanguage = (language: string) => {
      i18n.locale.value = language
      // axios.defaults.headers.common['Accept-Language'] = language;
      router.push({ params: { locale: language } })
      emit('language:updated')
    }

    const toggleMenu = () => {
      menuShown.value = !menuShown.value
    }

    const logout = async() => {
      await authState.dispatch('logout')
      await globalState.dispatch('clear')
      await router.push({ name: 'login' })
    }

    router.afterEach((to, from, next) => {
      // Closes the menu on mobile after navigation
      if (type.value == 'xs' || type.value == 'sm') {
        menuShown.value = false
      }
    })

    return {
      authState,
      mainMenu,
      changeLanguage,
      toggleMenu,
      logout
    }
  }
})
</script>