import Title from "@/models/title";
import BaseProvider from "./baseprovider"

export default class TitleProvider extends BaseProvider {
    public async fetchTitles(): Promise<Title[]> {
        const response = await (await this.getApiV2()).get('/Titles');

        const titles = response.data.map((d: any) => new Title(d));

        return titles;
    }
}