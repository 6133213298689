import BaseProvider from '@/providers/baseprovider';
import GlobalUserPermission from '@/models/globaluserpermission';

export interface getPermissionsParams {
    globalUserId?: number;
}

export default class PermissionProvider extends BaseProvider {
    public async getPermissions(params: getPermissionsParams): Promise<GlobalUserPermission[]> {
        const response = await (await this.getApiV2()).get(`/permissions/${params.globalUserId}`);
        return response?.data;
    }
}