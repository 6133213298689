export default class PaymentTerm {
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public nb_days: number = 0;
    public end_of_month: boolean = false;

    constructor(init?: Partial<PaymentTerm>) {
        Object.assign(this, init);
    }
}