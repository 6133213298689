<template>
  <div
      id="nav-level2"
      v-if='subMenu'>
    <h6 class="sidebar-heading">{{ subMenu.title }}</h6>
    <ul
        class="bo-list"
        v-if='subMenu.subItems?.length'>
      <template
          :key='index'
          v-for='(item, index) in subMenu.subItems?.filter((i: SubMenuItem) => (i.group && i?.items?.length) || (!i.group && !i?.items?.length))'>
        <!-- Normal subMenuItem -->
        <SidebarSingleMenuItem
            v-if='!item.group && !item?.items?.length'
            :item='item' />

        <!-- Multi items with collapse -->
        <SidebarCollapsableItemWrapper
            v-else
            :item='item' />
      </template>

      <!-- Accordion subMenu Item -->
      <SidebarAccordionItem
          v-if="subMenu.subItems?.filter((i) => i.group && !i?.items?.length).length > 0"
          :items='subMenu.subItems' />
    </ul>
    <br /><br />
    <div id="teleport-sidemenu"></div>
  </div>
</template>

<script lang="ts">
import SidebarAccordionItem from '@/components/Layout/Sidebar/SidebarAccordionItem.vue'
import SidebarCollapsableItemWrapper
  from '@/components/Layout/Sidebar/SidebarCollapsableItem/SidebarCollapsableItemWrapper.vue'
import SidebarSingleMenuItem from '@/components/Layout/Sidebar/SidebarSingleMenuItem.vue'
import useLocalization from '@/hooks/localization'
import { getSubMenu, SubMenu, SubMenuItem } from '@/menu/menu'
import DatawarehouseViewModel from '@/models/view/datawarehouseviewmodel'
import ListingViewModel from '@/models/view/listingviewmodel'
import DatawarehouseProvider from '@/providers/datawarehouseprovider'
import ListingProvider from '@/providers/listingprovider'
import { useAuthState } from '@/states/auth'
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  computed: {
    SubMenuItem() {
      return SubMenuItem
    }
  },
  components: { SidebarCollapsableItemWrapper, SidebarAccordionItem, SidebarSingleMenuItem },
  setup() {
    const authState = useAuthState()
    const localization = useLocalization()
    const route = useRoute()

    const listings = ref<ListingViewModel[]>()
    const datawarehouses = ref<DatawarehouseViewModel[]>()

    const listingProvider = new ListingProvider()
    const datawarehouseProvider = new DatawarehouseProvider()

    const subMenu = ref<SubMenu | undefined>()

    const loadListings = async() => {
      const response = await listingProvider.getDashboardListingViewModels()

      listings.value = response?.sort((a, b) =>
          (localization.localize(a.globalCategory) ?? '').localeCompare(
              localization.localize(b.globalCategory) ?? ''
          )
      )
    }

    const loadDatawarehouses = async() => {
      const response = await datawarehouseProvider.getDashboardDatawarehouseViewModels()

      datawarehouses.value = response?.sort((a, b) =>
          (localization.localize(a.globalCategory) ?? '').localeCompare(
              localization.localize(b.globalCategory) ?? ''
          )
      )
    }

    watch(() => authState.state.globalUser, async(newVal) => {
      if (newVal) {
        await Promise.all([ loadListings(), loadDatawarehouses() ])
      } else {
        listings.value = undefined
        datawarehouses.value = undefined
      }
    }, { immediate: true })

    watch([
          () => authState.state.organization?.is_system,
          () => authState.state.globalUser?.is_admin,
          () => route.name,
          listings,
          datawarehouses
        ],
        ([ isSystem, isAdmin, routeName, newListings, newDatawarehouses ]) => {
          const subMenuBasedOnRoute: SubMenu | undefined = getSubMenu(
              isSystem ?? false,
              isAdmin ?? false,
              routeName,
              newListings,
              newDatawarehouses
          )

          if (!subMenuBasedOnRoute || JSON.stringify(subMenuBasedOnRoute) === JSON.stringify(subMenu.value)) {
            return
          }

          subMenu.value = subMenuBasedOnRoute
        }, { deep: true, immediate: true })

    return {
      subMenu
    }
  }
})
</script>