export default class Matrix {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public nb_rows: number = 0;
    public nb_columns: number = 0;
    public quick_access: boolean = false;
    public item_azure_image1_width?: number | null;
    public item_azure_image1_height?: number | null;
    public entity_id?: number;

    public azure_image1?: string;
    constructor(init?: Partial<Matrix>) {
        Object.assign(this, init);
    }
}