export default class Zone {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_nl?: string;
    public name_en?: string;
    public price_list_id?: number;
    public appearance?: string;
    public store_id?: number;
    public active?: boolean;
    public order_number?: number;

    constructor(init?: Partial<Zone>) {
        Object.assign(this, init);
    }

    fromJson(init: any): Zone {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) {
                console.warn(`Zone fromJson conversion warning: ${key} is missing`)
            }
        }
        return this;
    }

    toJson(): any {
        return {
            ...this
        }
    }
}