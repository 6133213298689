import axios, {AxiosInstance, CreateAxiosDefaults, Method} from 'axios';
const oauth = require("axios-oauth-client")
import qs from 'qs';

import Config from '@/plugins/config';

import {
    AxiosCacheInstance,
    CacheOptions,
    CacheProperties,
    CacheRequestConfig,
    setupCache
} from 'axios-cache-interceptor';
import {authState} from '@/states/auth';
import useAppState from '@/states/app';
import filters from '@/helpers/filters';
import {RouteLocationNormalized, useRouter} from 'vue-router';

const DEFAULTS: CacheOptions = {

}

export default class  Cached {
    private static _defaultTTL = 1000 * 60 * 5;

    private static _axiosInstance: AxiosCacheInstance | null = null;
    private static _appState: any;
    private static _router: any;
    private static _currentRoute: string;

    private static get axiosInstance(): AxiosInstance {
        if (!Cached._axiosInstance) {
            Cached._axiosInstance = setupCache(axios.create(), DEFAULTS);
            Cached._axiosInstance.interceptors.request.use(
                (config) => ({ ...config, meta: { startRoute: Cached._currentRoute }})
            );
            Cached._axiosInstance.interceptors.response.use(
                (response: any) => response,
                async function (error) {
                    const config = error.config;

                    if (error.response.status === 401 && !config._retry) {
                        config._retry = true;
                        await authState.dispatch('refreshToken');
                        config.headers['Authorization'] = "Bearer " + authState.state.accessToken;
                        return Cached._axiosInstance!(config);
                    } else if ((error.response.status >= 400 && error.response.status <= 599) &&
                               (error?.config?.meta?.startRoute === Cached._currentRoute) &&
                               error.response.data.message.includes('timeout'))   {
                        let errorCode = filters.cflt(`GenericWords.RestCodes_${error.response.status}`);

                        errorCode = errorCode === `GenericWords.RestCodes_${error.response.status}` ?
                            error.code :
                            errorCode;

                        let errorMessage = filters.cflt(`GenericWords.RestMessages_${error.response.status}`);
                        errorMessage = errorMessage === `GenericWords.RestMessages_${error.response.status}` ?
                            `<br/><br/>${error.message}` :
                            `<br/><br/>${errorMessage}`;

                        let errorDetail = filters.cflt(`GenericWords.RestDetails_${error.response.status}`);
                        errorDetail = errorDetail === `GenericWords.RestDetails_${error.response.status}` ?
                            ``  :
                            `<br/><br/>${errorDetail}`;

                        Cached._appState.showNotification({
                            type: 'error',
                            subtitle: filters.cflt('GenericWords.RestError'),
                            message: `<div class="w-full text-center pt-3 pb-6">[${error.response.status} - ${errorCode}]${errorMessage}${errorDetail}</div>`,
                            showSend: true
                        });
                    }
                    console.warn ('Error: ', error);
                    return Promise.resolve();
                });
            Cached._appState = useAppState();
            Cached._router = (document!.querySelector('#app')! as any).__vue_app__?.config.globalProperties.$router;
            Cached._router.afterEach((to:  RouteLocationNormalized) => Cached._currentRoute = to.fullPath);
        }

        return Cached._axiosInstance!;
    }

    public static request = async (
        method: Method, url: string, params?: any, data?: any,
        version: number = 2, publicApi: boolean = false, cache: boolean | Partial<CacheProperties<any, any>> | undefined = false): Promise<any> => {
        const config: CacheRequestConfig = {
            method,
            baseURL: `${await Config.getBaseAPI()}/api/v${version}${publicApi ? '/public' : ''}/${url}`,
            headers: {
                "Content-type": "application/json"
            },
            params,
            data,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            cache: typeof cache === 'boolean' ? (cache ? { ttl: Cached._defaultTTL } : false) :
                typeof cache === 'undefined' ? undefined :
                    cache
        }

        if (authState.state.accessToken) { config.headers!.Authorization = `Bearer ${authState.state.accessToken}` }
        if (params?.language) { config.headers!["Accept-Language"] = params.language; }

        return Cached.axiosInstance.request(config);
    }
}