import { ConsumptionType } from '@/models/enums/consumptiontype.enum'
import { StockMode } from '@/models/enums/stockmode.enum'
import StoreFreePaymentType from '@/models/storefreepaymenttype'
import StorePos from '@/models/storepos'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import SlotTemplate from './slottemplate'
import StoreMedia from './storemedia'
import StoreWebhookEvent from './storewebhookevent'

export default class Store {
    [key: string]: any;
    id?: number;
    uid: string = uuidv4();
    entity_id?: number;
    language?: string;
    name_fr?: string;
    name_en?: string;
    name_nl?: string;
    vat_number?: string;
    creation_date?: Date;
    modification_date?: Date;
    address_line1?: string;
    address_line2?: string;
    zip_code?: string;
    city?: string;
    country_id?: number;
    email?: string;
    active?: boolean = true;
    resupply?: boolean = true;
    production?: boolean = false;
    opening_hour?: Date;
    header_note?: string;
    footer_note?: string;
    automatic_logoff?: boolean = false;
    force_upload_sale?: boolean = false;
    admin_pincode: string = "23456";
    consumption_type: ConsumptionType = ConsumptionType.TAKEAWAY;
    default_consumption_type?: ConsumptionType;
    default_price_list_id?: number;
    easystore_quote_journal_id?: number;
    easystore_order_journal_id?: number;
    stock_mode: StockMode = StockMode.CLOUD;
    print_free_item: boolean = true;
    order_transfer_confirmation: boolean = false;
    negative_stock_permission: boolean = true;
    show_cancelled_line_on_ticket: boolean = true;
    auto_kitchen_service: boolean = false;
    auto_create_tasks_in_order: boolean = false;
    cash_management_show_theorical_amount: boolean = false;
    mixandmatch_auto: boolean = false;
    auto_out_of_stock_notification: boolean = false;
    sales_lifetime: number = 120;
    delay_before_take_onsite_order: number = 30;
    delay_before_take_takeaway_order: number = 30;
    delay_before_take_delivery_order: number = 30;
    joyn_partner_key?: string;
    joyn_secret_password?: string;
    joyn_shop_reference?: string;
    order_transfer: boolean = false;
    email_receipt_subject?: string = undefined;
    email_receipt_body_html?: string = undefined;
    email_receipt_body_text?: string = undefined;
    picker_interface_french?: boolean = false;
    picker_interface_english?: boolean = false;
    picker_interface_dutch?: boolean = false;
    picker_interface_german?: boolean = false;
    queue_screen_bg_image?: string;
    queue_screen_bg_color_1?: string;
    queue_screen_bg_color_2?: string;
    queue_screen_logo?: string;
    queue_screen_message?: string;
    queue_screen_message_duration?: number = 10;
    table_lock_by_owner?: boolean = false;
    ask_rank_simplify?: boolean = false;
    order_number?: number;
    medias?: StoreMedia[];
    webhook_events?: StoreWebhookEvent[];
    slot_templates?: SlotTemplate[];
    duty_fiscal_store: boolean = false;
    sale_number_display_length?: number = 4;
    pos?: StorePos[];
    pos_logo_type?: number = 0;
    kiosk_proposition_allow_skip?: boolean = false;
    auto_close_cash_management_mode?: boolean = false;
    auto_close_cash_management_hour?: Date;
    document_language_option?: number;
    store_transfer_flow_module?: boolean = false;
    transfer_orders_before_close_cash_management?: boolean = false;
    mandatory_nominative_ticket?: boolean = false;
    closing_by_user?: boolean = false;
    item_not_found_sound_notification?: boolean = false;
    auto_archive?: boolean = false;
    lock_order_backoffice?: boolean = false;
    automatic_send_preparation_ticket_before_payment?: boolean = false;
    confirmation_to_send_preparation_ticket?: boolean = false;
    print_sale_note_on_ticket?: boolean = false;
    allow_sale_with_customer_system?: boolean = false;
    menu_encoding_type: number = 0;
    manage_consignment_sale: boolean = false;
    manage_split_amount: boolean = false;
    dinner_table_allow_zero_capacity: boolean = false;
    force_transfer_order: boolean = false;
    create_kitchen_task_by_rank: boolean = false;
    kitchen_ready_status_display_delay: number = 0;
    allow_fidelity_with_discount: boolean = false;
    buttons_quantity_screen?: string;

    constructor(init?: Partial<Store>) {
        Object.assign(this, init);
    }

    fromJson(init: any): Store {
        for (const key in init) {
            try {
                switch (key) {
                    case 'creation_date':
                    case 'modification_date':
                    case 'opening_hour':
                    case 'auto_close_cash_management_hour':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    case 'medias':
                        this[key] = [];

                        init[key].forEach((l: any, i: number) => {
                            const n = new StoreMedia();
                            n.fromJson(l);

                            this[key]?.push(n);
                        });

                        this[key] = this[key]?.sort((a: StoreMedia, b: StoreMedia) => (a.type ?? 0) - (b.type ?? 0));
                        break;

                    case 'webhook_events':
                        this[key] = [];

                        init[key].forEach((l: any, i: number) => {
                            const n = new StoreWebhookEvent();
                            n.fromJson(l);

                            this[key]?.push(n);
                        });

                        this[key] = this[key]?.sort((a: StoreWebhookEvent, b: StoreWebhookEvent) => (a.event_type ?? 1) - (b.event_type ?? 1));
                        break;

                    case 'slot_templates':
                        this[key] = [];

                        init[key].forEach((l: any, i: number) => {
                            const n = new SlotTemplate();
                            n.fromJson(l);

                            this[key]?.push(n);
                        });

                        // this[key] = this[key]?.sort((a: SlotTemplate, b: SlotTemplate) => (a.consumption_type ?? 1) - (b.consumption_type ?? 1));
                        break;

                    //
                    case 'store_free_payment_types':
                        this[key] = [];

                        init[key].forEach((l: any, i: number) => {
                            const n = new StoreFreePaymentType();
                            n.fromJson(l);

                            this[key]?.push(n);
                        });

                        break;

                    case 'pos': {
                        this[key] = [];

                        init[key].forEach((l: any, i: number) => {
                            const n = new StorePos();
                            n.fromJson(l);

                            this[key]?.push(n);
                        });

                        // this[key] = this[key]?.sort((a: SlotTemplate, b: SlotTemplate) => (a.consumption_type ?? 1) - (b.consumption_type ?? 1));
                        break;
                    }

                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`Store fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }

    toJson(): any {
        return {
            ...this,
            opening_hour: this.opening_hour ?
                moment(this.opening_hour)
                    .set('year', 1900)
                    .set('month', 0)
                    .set('date', 1)
                    .toDate().toLocaleString() :
                undefined,
            auto_close_cash_management_hour: this.auto_close_cash_management_hour ?
                moment(this.auto_close_cash_management_hour)
                    .set('year', 1900)
                    .set('month', 0)
                    .set('date', 1)
                    .toDate().toLocaleString() :
                undefined,
            slot_templates: this.slot_templates?.map((st: SlotTemplate) => {
                return {
                    ...st,
                    start_time: st.start_time ? moment(st.start_time)
                        .set('year', 1900)
                        .set('month', 0)
                        .set('date', 1)
                        .toDate().toLocaleString() :
                        undefined,
                    end_time: st.end_time ? moment(st.end_time)
                        .set('year', 1900)
                        .set('month', 0)
                        .set('date', 1)
                        .toDate().toLocaleString() :
                        undefined,
                    slot_duration: st.slot_duration ? moment(st.slot_duration)
                        .set('year', 1900)
                        .set('month', 0)
                        .set('date', 1)
                        .toDate().toLocaleString() :
                        undefined,
                };
            }),
            store_free_payment_types: this.store_free_payment_types?.map((pt: StoreFreePaymentType) => {
                return {
                    ...pt,
                };
            })
        };
    }
}
