
import { StoreTransferRepository, StoreTransferLineRepository, StoreTransferHeaderRepository, IStoreTransferRepositoryParameters } from "@/models/repository/storetransferrepository";
import { StoreTransferDirectHeader, StoreTransferDirectLine } from "../document/storetransferdirectdocument";


export class StoreTransferDirectHeaderRepository extends StoreTransferHeaderRepository<StoreTransferDirectHeader> {

    constructor(init?: Partial<StoreTransferDirectHeader>) {
        super(StoreTransferDirectHeader, init);
    }
}

export class StoreTransferDirectLineRepository extends StoreTransferLineRepository<StoreTransferDirectLine> {

    constructor(init?: Partial<StoreTransferDirectLine>) {
        super(StoreTransferDirectLine, init);
    }
}

interface IStoreTransferDirectRepositoryParameters extends IStoreTransferRepositoryParameters<StoreTransferDirectHeaderRepository, StoreTransferDirectLineRepository> {

}

export class StoreTransferDirectRepository extends StoreTransferRepository<StoreTransferDirectHeaderRepository, StoreTransferDirectLineRepository> {
    constructor(params?: IStoreTransferDirectRepositoryParameters) {
        super(StoreTransferDirectHeaderRepository, StoreTransferDirectLineRepository, params?.header, params?.lines);
    }
}