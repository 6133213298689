import Allergen from "@/models/allergen";
import BaseProvider from "./baseprovider"

export default class AllergenProvider extends BaseProvider {
    public async fetchAllergens(): Promise<Allergen[]> {
        const response = await (await this.getApiV2()).get('/Allergens');

        const allergens = response.data.map((d: any) => new Allergen(d));

        return allergens;
    }
}