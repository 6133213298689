import axios, { AxiosResponse } from 'axios';

export default class Config {
    private static config: any;

    public static async read(): Promise<AxiosResponse> {
        return axios.create({
            baseURL: "/",
            headers: {
                "Content-type": "application/json",
            }
        }).get("/config.json");
    }

    public static async get(): Promise<any> {
        if (!this.config) {
            this.config = (await this.read()).data;
        }

        return this.config;
    }

    public static async getBaseAPI(): Promise<string> {
        return (await this.get()).base_api;
    }
}