import GlobalModule from "@/models/globalmodule";
import BaseProvider from "./baseprovider"

export default class GlobalModuleProvider extends BaseProvider {
    public async fetchGlobalModules(): Promise<GlobalModule[]> {
        const response = await (await this.getApiV2()).get('/GlobalModules');

        const globalModules = response.data.map((d: any) => this.getGlobalModule(d));

        return globalModules;
    }

    private getGlobalModule(data: any): GlobalModule {
        const globalModule = new GlobalModule(data);

        return globalModule;
    }
}