export interface IVies {
    valid: boolean;
    countryCode?: string;
    vatNumber?: string
    requestDate?: Date;
    name?: string;
    address?: string[];
    error?: string;
}

export class Vies implements IVies {
    valid: boolean = false;
    countryCode?: string;
    vatNumber?: string;
    requestDate?: Date;
    name?: string;
    address?: string[];
    error?: string;
    constructor(init?: IVies) {
        Object.assign(this, init);
    }
}
