import ItemLevel from "@/models/itemlevel";
import PagedItemLevels from "@/models/paged/pageditemlevels";
import BaseProvider from "./baseprovider"
import { PagedParams } from '@/models/paged/pagedparams';

export default class ItemLevelProvider extends BaseProvider {
    public async fetchItemLevels(params?: {
        entityIds?: Array<number>,
        types?: Array<number>
    }): Promise<ItemLevel[]> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("type", params?.types, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/itemLevels${p}`);

        const itemLevels = response.data.map((d: any) => new ItemLevel(d));

        return itemLevels;
    }

    public async fetchPagedItemLevels(
        params?: {
            entityIds?: Array<number>,
            types?: Array<number>
        },
        pagedParams?: PagedParams
    ): Promise<PagedItemLevels> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("type", params?.types, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/itemlevels${p}`);

        const pagedItems = new PagedItemLevels(response.data);

        return pagedItems;
    }

    public async getItemLevelFromUid(itemLevelUid: string): Promise<ItemLevel> {
        const response = await (await this.getApiV2()).get(`/itemlevels/${itemLevelUid}`);

        const itemLevels = this.getItemLevel(response.data);

        return itemLevels;
    }

    public async getItemLevelFromId(itemLevelId: number): Promise<ItemLevel> {
        const response = await (await this.getApiV2()).get(`/itemlevels/${itemLevelId}`);

        const itemLevels = this.getItemLevel(response.data);

        return itemLevels;
    }

    public async saveItemLevel(itemLevel: ItemLevel): Promise<ItemLevel> {
        const response = await (await this.getApiV2()).post(`/itemlevels/`, itemLevel);

        const items = this.getItemLevel(response.data);

        return items;
    }

    private getItemLevel(data: any): ItemLevel {
        const itemLevel = new ItemLevel(data);

        itemLevel.color = itemLevel.color ? Number(itemLevel.color) : undefined;

        return itemLevel;
    }
}
