import AuthProvider from "@/providers/authprovider";
import DashProvider from "@/providers/dashprovider";
import GlobalModuleProvider from "@/providers/globalmoduleprovider";
import GlobalUserProvider from "@/providers/globaluserprovider";
import ItemLevelProvider from "@/providers/itemlevelprovider";
import ItemProvider from "@/providers/itemprovider";
import MailTemplateProvider from '@/providers/mailtemplateprovider'
import MatrixProvider from "@/providers/matrixprovider";
import OrganizationProvider from "@/providers/organizationprovider";
import StoreProvider from "@/providers/storeprovider";
import SaleProvider from "@/providers/saleprovider";
import PropositionProvider from "@/providers/propositionprovider";
import CustomerProvider from "@/providers/customerprovider";
import EntityProvider from "@/providers/entityprovider";
import SupplierProvider from "@/providers/supplierprovider";
import PurchaseProvider from "@/providers/purchaseprovider";
import POSProvider from '@/providers/posprovider';
import PriceListProvider from '@/providers/pricelistprovider';
import PrinterProvider from '@/providers/printerprovider';
import CurrencyProvider from '@/providers/currencyprovider';
import DiscountReasonProvider from "@/providers/discountreasonprovider";
import StoreTransferFlowProvider from "@/providers/storetransferflowprovider";
import JournalProvider from "@/providers/journalprovider";
import CountryProvider from '@/providers/countryprovider';
import StoreTransferDirectProvider from "@/providers/storetransferdirectprovider";
import StorePrinterProvider from '@/providers/storeprinterprovider';
import ZoneProvider from '@/providers/zoneprovider';
import PrinterModelProvider from '@/providers/printermodelprovider';
import TaskTypeProvider from '@/providers/tasktypeprovider';
import ItemCategoryProvider from '@/providers/itemcategoryprovider';
import PermissionProvider from '@/providers/permissionprovider';
import SignalRProvider from '@/providers/signalrprovider';
import RankProvider from '@/providers/rankprovider';
import AccountingProvider from '@/providers/accountingprovider';
import MeasureUnitProvider from '@/providers/measureunitprovider';
import MaterializeProvider from '@/providers/materializeprovider';
import ExternalDataImportProvider from '@/providers/externaldataimportcontroller';
import MatrixTemplateProvider from "@/providers/matrixtemplateprovider";
import ErrorProvider from '@/providers/errorprovider';
import AddressProvider from "@/providers/addressprovider";
import DatawarehouseProvider from "@/providers/datawarehouseprovider";
import ListingProvider from "@/providers/listingprovider";
import AllergenProvider from "@/providers/allergenprovider";
import DocumentStatusProvider from "@/providers/documentstatusprovider";
import DutyProvider from "@/providers/dutyprovider";
import IntrastatProvider from "@/providers/intrastatprovider";
import PaymentTermProvider from "@/providers/paymenttermprovider";
import PermissionKeysProvider from "@/providers/permissionkeyprovider";
import TagProvider from "@/providers/tagprovider";
import TitleProvider from "@/providers/titleprovider";
import VATProvider from "@/providers/vatprovider";
import ZipCodeProvider from "@/providers/zipcodeprovider";
import ViesProvider from "@/providers/external/viesprovider";
import LicenseProvider from '@/providers/licenseprovider';

export default function useProvider() {
    const accounting = new AccountingProvider();
    const address = new AddressProvider();
    const auth = new AuthProvider();
    const country = new CountryProvider();
    const currency = new CurrencyProvider();
    const customer = new CustomerProvider();
    const dash = new DashProvider();
    const discountReason = new DiscountReasonProvider();
    const entity = new EntityProvider();
    const error = new ErrorProvider();
    const externalDataImport = new ExternalDataImportProvider();
    const globalModule = new GlobalModuleProvider();
    const globalUser = new GlobalUserProvider();
    const item = new ItemProvider();
    const itemCategory = new ItemCategoryProvider();
    const itemLevel = new ItemLevelProvider();
    const journal = new JournalProvider();
    const license = new LicenseProvider();
    const materialize = new MaterializeProvider();
    const matrix = new MatrixProvider();
    const matrixTemplate = new MatrixTemplateProvider();
    const measureUnit = new MeasureUnitProvider();
    const organization = new OrganizationProvider();
    const permission = new PermissionProvider();
    const pos = new POSProvider();
    const priceList = new PriceListProvider();
    const printer = new PrinterProvider();
    const printerModel = new PrinterModelProvider();
    const proposition = new PropositionProvider();
    const purchase = new PurchaseProvider();
    const rank = new RankProvider();
    const sale = new SaleProvider();
    const signalr = new SignalRProvider();
    const store = new StoreProvider();
    const storePrinter = new StorePrinterProvider();
    const storeTransferFlow = new StoreTransferFlowProvider();
    const storeTransferDirect = new StoreTransferDirectProvider();
    const supplier = new SupplierProvider();
    const taskType = new TaskTypeProvider();
    const zone = new ZoneProvider();
    const datawarehouse = new DatawarehouseProvider();
    const mailTemplate = new MailTemplateProvider();
    const listing = new ListingProvider();
    const allergen = new AllergenProvider();
    const documentStatus = new DocumentStatusProvider();
    const duty = new DutyProvider();
    const intrastat = new IntrastatProvider();
    const paymentTerm = new PaymentTermProvider();
    const permissionKey = new PermissionKeysProvider();
    const tag = new TagProvider();
    const title = new TitleProvider();
    const vat = new VATProvider();
    const zipCode = new ZipCodeProvider();
    const vies = new ViesProvider();

    return {
        accounting,
        address,
        allergen,
        auth,
        country,
        currency,
        customer,
        dash,
        discountReason,
        documentStatus,
        duty,
        entity,
        error,
        externalDataImport,
        globalModule,
        globalUser,
        intrastat,
        item,
        itemCategory,
        itemLevel,
        journal,
        license,
        materialize,
        matrix,
        matrixTemplate,
        measureUnit,
        organization,
        paymentTerm,
        permission,
        permissionKey,
        pos,
        priceList,
        printer,
        printerModel,
        proposition,
        purchase,
        rank,
        sale,
        signalr,
        store,
        storePrinter,
        storeTransferFlow,
        storeTransferDirect,
        supplier,
        tag,
        taskType,
        title,
        zone,
        datawarehouse,
        mailTemplate,
        listing,
        vat,
        vies,
        zipCode,
    };
}