export default class IntrastatTransactionType {
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public code?: number;

    constructor(init?: Partial<IntrastatTransactionType>) {
        Object.assign(this, init);
    }
}