export interface CustomerEntity {
    entity_id: number;
    accounting_id?: string;
    accounting_uid?: string;
}

export class CustomerAddress {
    id?: number;
    uid?: string;
    name?: string;
    address_line?: string;
    address_line2?: string;
    zip_code?: string;
    city?: string;
    country_id?: number;
    country?: string;
    phone_number?: string;
    webshop_id?: number;
}

export default class Customer {
    [key: string]: any;
    public uid?: string;
    public id?: number;
    public first_name?: string;
    public last_name?: string;
    public email?: string;
    public administrative_zip_code?: string;
    public administrative_address_line?: string;
    public administrative_address_line2?: string;
    public administrative_city?: string;
    public administrative_country_id?: number;
    public administrative_country?: string;
    public birthday?: Date;
    public phone_number?: string;
    public mobile_number?: string;
    public vat_number?: string;
    public name?: string;
    public customer_account?: boolean;
    public title_id?: number;
    public price_list_id?: number;
    public note?: string;
    public warning?: string;
    public ask_invoice?: boolean;
    public language?: string;
    public code?: string;
    public active?: boolean = true;
    public invoice_frequency_type?: number;
    public invoice_email?: string;
    public gender?: number;
    public fidelity_point?: number;
    public fidelity_excluded?: boolean;
    public fidelity_card_barcode?: string;
    public origin?: string;
    public newsletter?: boolean;
    public cashless_wallet?: boolean;
    public is_system?: boolean;
    public national_number?: string;
    public picture?: string;
    public close_customer_account?: boolean;
    public payment_term_id?: number;
    public duty_active: boolean = false;
    public global_user_id?: number;
    public is_b2b: boolean = false;
    public regime_type?: number = 1;

    public pos_hide: boolean = false;
    public pos_hide_history: boolean = false;
    public entities?: CustomerEntity[] = [];
    public addresses?: CustomerAddress[] = [];
    
    // Zephyr properties
    public webshop_id?: number;
    public webshop_active?: boolean = false;
    public webshop_address_id?: number;
    public webshop_invoice_address_id?: number;

    constructor(init?: Partial<Customer>) {
        Object.assign(this, init);
    }
}
