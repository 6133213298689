export default class CASummaryPaymentsModel {
    [key: string]: any;
    group_date?: Date;
    store_id!: number;
    payment_type_id?: number;
    store_free_payment?: boolean;
    payment_name_fr?: string;
    payment_name_nl?: string;
    payment_name_en?: string;
    amount?: number;
    constructor(init: Partial<CASummaryPaymentsModel> & Required<{store_id: number}>) {
        Object.assign(this, init);
        if (init.group_date) this.group_date = new Date(init.group_date);
    }
}