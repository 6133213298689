import { InjectionKey } from 'vue'
import { createStore, useStore as useVuexStore, Store as VuexStore } from 'vuex'

import {
  executeRestAction,
  failedRestAction,
  initialRestAction,
  RestAction,
  successRestAction,
} from '@/states/restAction'
import { deepCopy } from '@/helpers/deepCopy'
import CurrencyProvider from '@/providers/currencyprovider'
import Currency from '@/models/currency'

export interface CurrencyState {
  provider: CurrencyProvider
  ready: boolean
  values: RestAction<Currency[]>
}

const initialState: CurrencyState = {
  provider: new CurrencyProvider(),
  ready: false,
  values: initialRestAction(),
}

export const currencyState: VuexStore<CurrencyState> =
  createStore<CurrencyState>({
    state: initialState,
    mutations: {
      ready(state, payload: boolean) {
        state.ready = payload
      },
      init(state) {
        state = initialState
      },
      load(state, payload: Partial<CurrencyState>) {
        state.ready = true
        if (payload.values) state.values = deepCopy(payload.values)
      },
    },
    getters: {
      all: (state) => state.values.data ?? [],
      getSymbol: (state) => (currencyId: number): string => {
        const currency = state.values.data?.find((currency: Currency) => currency.id === currencyId)

        if (!currency) {
          return 'EUR'
        }

        switch (currency.iso_code ?? 'EUR') {
          case 'EUR':
            return '€';
          case 'USD':
            return '$';
          case 'GBP':
            return '£';
          default:
            return '€';
        }
      },
    },
    actions: {
      async load(store) {
        store.commit('ready', false)
        store.state.values = executeRestAction()
        try {
          store.commit('load', {
            values: successRestAction(
              await store.state.provider.fetchCurrencies()
            ),
          })
        } catch (e: any) {
          store.state.values = failedRestAction(e.message)
        }
      },
    },
    plugins: [(store) => store.dispatch('load')],
  })

export const currencyStateKey: InjectionKey<VuexStore<CurrencyState>> =
  Symbol()
export const useCurrencyState = (): VuexStore<CurrencyState> =>
  useVuexStore(currencyStateKey)
export default useCurrencyState
