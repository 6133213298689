import axios from 'axios';

export interface RestAction<T> {
    executed: boolean;
    pending: boolean;
    error: string | null;
    data: T | null
}

export interface RestActionResult<T> {
    error: string | null;
    data: T | null
}

export const initialRestAction = <T>(): RestAction<T> => ({ executed: false, pending: false, error: null, data: null });
export const executeRestAction = <T>(): RestAction<T> => ({ executed: true, pending: true, error: null, data: null });
export const successRestAction = <T>(data: T): RestAction<T> => ({ executed: true, pending: false, error: null, data });
export const failedRestAction = <T>(error: any): RestAction<T> => {

    const r = {
        executed: true,
        pending: false,
        data: null
    };

    if (axios.isAxiosError(error)) {
        if (error.response)
            return {
                ...r,
                error: `Error Data: ${error.response!.data}\rStatus: ${error.response!.status}\rHeaders: ${error.response!.headers}`
            }
        else if (error.request) return {
                ...r,
                error: `No response received: ${error.request}`
            }
        else return {
            ...r,
            error: `Error: ${error.message}`
        }
    } else return { ...r, error: error?.message ?? error }
}

export const hasRestActionInitiated = <T>(restAction: RestAction<T>): boolean => !restAction.executed;
export const hasRestActionPending = <T>(restAction: RestAction<T>): boolean => restAction.executed && restAction.pending;
export const hasRestActionFailed = <T>(restAction: RestAction<T>): boolean => restAction.executed && !restAction.pending && (restAction.error !== null || restAction.data == null);
export const hasRestActionSucceeded = <T>(restAction: RestAction<T>): boolean => restAction.executed && !restAction.pending && (restAction.error === null && restAction.data !== null);

