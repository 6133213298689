export default class Stock {
    store_id?: number;
    store_uid?: string;
    stock: number = 0;
    customer_ordered: number = 0;
    customer_delivered: number = 0;
    supplier_ordered: number = 0;
    supplier_delivered: number = 0;
    stock_minimum?: number;
    stock_maximum?: number;

    constructor(init?: Partial<Stock>) {
        Object.assign(this, init);
    }
}
