export default class StoreMedia {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public name?: string;
    public type?: number;
    public file_path?: string;
    public media_content?: any;

    constructor(init?: Partial<StoreMedia>) {
        Object.assign(this, init);
    }

    fromJson(init: any): StoreMedia {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`StoreMedia fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }
}