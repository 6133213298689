import BaseProvider from "./baseprovider"
import PagedAddresses from "@/models/paged/pagedaddresses"
import { PagedParams } from "@/models/paged/pagedparams"

export default class AddressProvider extends BaseProvider {
  public async fetchPagedCustomerAddresses(entityIds: number[], pagedParams?: PagedParams): Promise<PagedAddresses> {
    let p: String = ''
    p = p.addParams("entityIds", entityIds, true)
    p = p.addPagedParams(pagedParams, {
        pageNumber: 1,
        pageSize: 25,
        sortField: 'address_line',
        sortOrder: 'asc'
    })
    p = p !== '' ? `?${p}` : ''

    const response = await (await this.getApiV2()).get(`/CustomerAddresses${p}`)

    const pagedAddresses = new PagedAddresses(response.data);

    return pagedAddresses;
  }
}