export default class Payment {
    [key: string]: any;
    id?: number;
    document_id?: number;
    payment_type_id?: number;
    from_payment_id?: number;
    date?: Date;
    amount?: number;
    description?: string;
    document_type?: number;
    closed?: boolean;
    cashdrawer_number?: number;
    payment_terminal_transaction_id?: number;
    global_user_id?: number;
    cash_management_id?: number;
    origin?: string;
    accounting?: boolean;
    uid?: string;
    cash_management_uid?: string;
    accounting_creation?: Date;
    cash_back?: boolean;
    from_payment_uid?: string;
    sunday_payment_id?: number;

    constructor(init?: Partial<Payment>) { Object.assign(this, init); }
    fromJson(init: any) {
        for (const key in init) {
            try {
                switch (key) {
                    case 'accounting_creation':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`Payment fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
