export default class PriceList {
    [key: string]: any;
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public currency_id?: number;
    public order_number?: number;
    public consumption_type?: number;
    public default_discount_rate?: number;
    public uid?: string;
    public price_mode?: number;
    public calculated_discount_rate?: number;
    public entities?: any[] = [];

    constructor(init?: Partial<PriceList>) {
        Object.assign(this, init);
    }
}