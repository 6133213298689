export default class IntrastatCode {
    [key: string]: any;
    public id?: number;
    public code?: string;
    public name?: string;
    public extra_unit?: boolean;

    constructor(init?: Partial<IntrastatCode>) {
        Object.assign(this, init);
    }
}
