import BaseProvider from "./baseprovider"
import '../helpers/addParams';
import {PagedParams} from '@/models/paged/pagedparams';
import PagedLightSpeed from '@/models/paged/pagedlightspeedrecords';

export default class ExternalDataImportProvider extends BaseProvider {
    public async lightspeedPrepare(entityId: number, file: File): Promise<string> {
        let p: String = '';
        p = p.addParams("entityId", [entityId]);
        p = p !== '' ? `?${p}` : ''

        const formData = new FormData();
        formData.append('file', file);


        const response =
             await(await this.getApiV1())
            .post(`import/lightspeed/prepare${p}`, formData, { timeout: 0 });

        return response.data['importUid'] as string;
    }

    public async lightspeedPagedPreview(importUid: string, pagedParams: PagedParams): Promise<PagedLightSpeed> {
        let p: String = '';
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'receipt_id',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response =
            await(await this.getApiV1())
                .get(`import/lightspeed/preview/${importUid}${p}`);

        return response.data as PagedLightSpeed;
    }

    public async lightspeedExecute(
        importUid: string,
        journalId: number,
        importCustomers: boolean = false,
        importItems: boolean = false,
        importSales: boolean = false
    ): Promise<void> {
        let p: String = '';
        p = p.addParams("journalId", [journalId]);
        p = p.addParams("importCustomers", [importCustomers]);
        p = p.addParams("importItems", [importItems]);
        p = p.addParams("importSales", [importSales]);
        p = p !== '' ? `?${p}` : ''

        const response =
            await(await this.getApiV1())
                .post(`import/lightspeed/execute/${importUid}${p}`);

        return;
    }
}
