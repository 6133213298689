<template>
  <i v-if="icon" :class='iconClass'/>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { FontAwesomeFamilyType } from '@/types/FontAwesomeFamilyType'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: ''
    },
    family: {
      type: String as PropType<FontAwesomeFamilyType>,
    },
    sharp: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const iconClass = computed(() => {
      let classes = ['fas'];

      if (props.icon) {
        classes.push(`fa-${props.icon}`);
      }

      if (props.family) {
        classes.push(`fa-${props.family}`);
      }

      if (props.sharp) {
        classes.push('fa-sharp');
      }

      if (props.color) {
        classes.push(`color-${props.color}`);
      }

      return classes.join(' ');
    })

    return { iconClass }
  }
})
</script>