export default class MatrixButton {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public font_color?: number;
    public action_parameter_content?: string;
    public display_stock?: boolean = false;
    public open_proposition_automatically?: boolean = true;
    public item_level1_color?: string;
    public action_type?: string;
    public entity_id?: number;

    public description_fr?: string;
    public description_en?: string;
    public description_nl?: string;

    public azure_image1?: string;
    public azure_image1_name?: string;
    public azure_image1_base64?: string;
    public azure_image1_remove?: string; // Uncropped image. Remove before POST to avoid sending 2 b64...

    constructor(init?: Partial<MatrixButton>) {
        Object.assign(this, init);
    }
}