export default class ItemCategory {
    public uid?: string;
    public id?: number;
    public type?: number = 1;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public active?: boolean = true; // not exists ?
    public parent_item_category_id?: number;
    
    // Zephyr properties
    public webshop_id?: number;
    public webshop_active?: boolean = false;

    public entities?: any[] = [];

    constructor(init?: Partial<ItemCategory>) {
        Object.assign(this, init);
    }
}