export default class MeasureUnit {
    public uid?: string;
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public active?: boolean = true;

    constructor(init?: Partial<MeasureUnit>) {
        Object.assign(this, init);
    }
}