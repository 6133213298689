import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sidebar-heading",
  style: {"margin-top":"50px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        class: _normalizeClass({ 'pt-4': typeof _ctx.item === 'string' })
      }, [
        (typeof _ctx.item === 'string')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item), 1))
          : _withDirectives((_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              class: "bo-list-item",
              to: _ctx.getLink(_ctx.item?.link)
            }, {
              default: _withCtx(() => [
                (_ctx.item.icon)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass((_ctx.item.iconClass ?? 'fas') + ' fa-' + _ctx.item.icon + ' color-' + _ctx.item.color)
                    }, null, 2))
                  : (_ctx.item.iconPath)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        width: "28",
                        class: "image",
                        src: _ctx.item.iconPath
                      }, null, 8, _hoisted_2))
                    : (_ctx.item.iconHtml)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass('color-' + _ctx.item.color),
                          innerHTML: _ctx.item.iconHtml
                        }, null, 10, _hoisted_3))
                      : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: "bo-list-item-content",
                  innerHTML: _ctx.$t(_ctx.item?.name ?? '')
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["to"])), [
              [_vShow, _ctx.item.visible]
            ])
      ], 2))
    : _createCommentVNode("", true)
}